/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";

.suscribete__div--container {
    /*posicion y layout*/
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-image: url('../img/globales/suscribete.png');
    background-position: 28%;
    background-size: cover;
    /*misc*/

    .suscribete__div-paquete-info {
        /*posicion y layout*/
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        .suscribete__img--logo-galileo {
            /*posicion y layout*/
            position: relative;
            top: 45px;
            width: 60%;
            background-color: white;
            border-radius: 10px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }

        .suscribete__img--logo-aplicativo {
            /*posicion y layout*/
            width: 100%;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }

        .suscribete__p--vencio-suscripcion {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            font-size: 15px;
            font-family: $fuente-principal;
            font-weight: bold;
            text-align: center;
            /*visual*/
            /*misc*/
        }

        .suscribete__button--comprar-suscripcion {
            /*posicion y layout*/
            width: 90%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            /*modelo de caja*/
            /*tipografia*/
            text-decoration: none;
            font-family: $fuente-principal;
            font-weight: bold;
            /*visual*/
            background-color: $color-secundario;
            color: white;
            border: none;
            border-radius: 20px;
            /*misc*/
            cursor: pointer;
        }

        @media screen and (min-width: 350px){
            .suscribete__img--logo-galileo {
                /*posicion y layout*/
                width: 190px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .suscribete__img--logo-aplicativo {
                /*posicion y layout*/
                width: 310px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .suscribete__p--vencio-suscripcion {
                /*posicion y layout*/
                /*modelo de caja*/
                /*tipografia*/
                font-size: 18px;
                font-family: $fuente-principal;
                font-weight: bold;
                text-align: center;
                /*visual*/
                /*misc*/
            }

            .suscribete__button--comprar-suscripcion {
                /*posicion y layout*/
                width: 320px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }

        @media screen and (min-width: 800px){
            .suscribete__img--logo-galileo {
                /*posicion y layout*/
                width: 230px;
                top: 70px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .suscribete__img--logo-aplicativo {
                /*posicion y layout*/
                width: 400px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .suscribete__p--vencio-suscripcion {
                /*posicion y layout*/
                /*modelo de caja*/
                /*tipografia*/
                font-size: 30px;
                font-family: $fuente-principal;
                font-weight: bold;
                text-align: center;
                /*visual*/
                /*misc*/
            }

            .suscribete__button--comprar-suscripcion {
                /*posicion y layout*/
                position: relative;
                width: 500px;
                height: 41px;
                bottom: 20px;
                /*modelo de caja*/
                /*tipografia*/
                font-size: 30px;
                /*visual*/
                /*misc*/
            }
        }
    }

    .suscribete__button--cerrar-sesion {
        /*posicion y layout*/
        width: 200px;
        height: 30px;
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        margin: auto;
        /*modelo de caja*/
        /*tipografia*/
        font-family: $fuente-principal;
        font-weight: bold;
        /*visual*/
        border: none;
        border-radius: 10px;
        background-color: $color-negativo;
        color: $color-blanco-fuente;
        /*misc*/
        cursor: pointer;
    }

    @media screen and (min-width: 800px){
        .suscribete__div-paquete-info {
            /*posicion y layout*/
            position: absolute;
            top: 60px;
            left: 100px;
            width: 500px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }

        .suscribete__button--cerrar-sesion {
            /*posicion y layout*/
            width: 200px;
            height: 30px;
            position: absolute;
            top: 50px;
            right: 50px;
            left: auto;
            margin: 0;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}

@media screen and (min-width: 800px){
    .suscribete__div--container {
        /*posicion y layout*/
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: auto;
        align-items: auto;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-image: url('../img/globales/suscribete.png');
        background-position: 28%;
        background-size: cover;
        /*misc*/
    }
}

@media screen and (max-height: 600px){
    .suscribete__div--container {
        /*posicion y layout*/
        height: 700px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";


.cabecero__header {
    /*posicion y layout*/
    position: relative;
    top: 80px;
    width: 100%;
    height: calc(100vh - 80px);
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: rgb(216, 216, 216);
    /*misc*/

    .cabecero__div--container {
        /*posicion y layout*/
        position: relative;
        width: 100%;
        height: 100%;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-image: url("../img/menu/cabecero.png");
        background-size: cover;
        background-position:  40%;
        /*misc*/

        .cabecero__div--logos {
            /*posicion y layout*/
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            .cabecero__img--logo-principal {
                /*posicion y layout*/
                width: 130px;
                position: absolute;
                top: 10px;
                right: 20px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                background-color: white;
                border-radius: 20px;
                /*misc*/
            }

            .cabecero__img--logo-aplicativo {
                /*posicion y layout*/
                width: 270px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            @media screen and (min-width: 400px){

                .cabecero__img--logo-aplicativo {
                    /*posicion y layout*/
                    width: 350px;
                    /*modelo de caja*/
                    /*tipografia*/
                    /*visual*/
                    /*misc*/
                }
            }

            @media screen and (min-width: 500px){

                .cabecero__img--logo-aplicativo {
                    /*posicion y layout*/
                    width: 400px;
                    /*modelo de caja*/
                    /*tipografia*/
                    /*visual*/
                    /*misc*/
                }
            }
        }


        @media screen and (min-width: 800px){
            .cabecero__div--logos {
                /*posicion y layout*/
                position: absolute;
                right: 210px;
                width: 40%;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/

                .cabecero__img--logo-principal {
                    /*posicion y layout*/
                    position: relative;
                    left: 0;
                    top: 50px;
                    width: 260px;
                    /*modelo de caja*/
                    /*tipografia*/
                    /*visual*/
                    /*misc*/
                }

                .cabecero__img--logo-aplicativo {
                    /*posicion y layout*/
                    width: 470px;
                    /*modelo de caja*/
                    /*tipografia*/
                    /*visual*/
                    /*misc*/
                }
            }
        }

        .cabecero__div--adorno-bajo {
            /*posicion y layout*/
            position: absolute;
            z-index: 2;
            bottom: 0;
            width: 100%;
            height: 80px;
            /*modelo de caja*/
            overflow: hidden !important;
            /*tipografia*/
            /*visual*/
            background-image: url("../img/globales/fondo-animado.png");
            background-position: top 10% right 0%;
            /*misc*/
            background-repeat: repeat;
            background-position: left top;
            background-size: 1500px;
            transform: scale(1);
            /*misc*/
            animation: animacion-fondo-menu 100s infinite;
            background-color: $color-primario;
            box-shadow: 0px 0px 13px #000000;
        }
    }
}


@keyframes animacion-fondo-menu{
    0% {background-position: 500px 500px}
    50%{background-position: 100px 100px}
    100%{background-position: 500px 500px}
}

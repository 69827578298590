/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";


.tarjeta-bloqueada__div--container {
    /*posicion y layout*/
    position: relative;
    width: 250px;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    border-radius: 10px;
    background-position: top 10% right 0%;
    background-image: linear-gradient(45deg , #5435ff, #f0ff67, #43ff43, #c64cff, #ff5151, #ff52f6, #4c38ff, #43ff4c, #5435ff, #f0ff67, #ff52f6 ,#52daff, #5435ff, #f0ff67, #43ff43, #c64cff, #ff5151, #ff52f6, #4c38ff, #43ff4c, #5435ff, #f0ff67, #ff52f6 ,#52daff  );
    background-size: 1400% 1400%;
    /*misc*/
    animation: moveGradient 15s ease-in-out infinite;

    .tarjeta-bloqueada__button {
        /*posicion y layout*/
        width: 94%;
        height: 97%;
        display: flex;
        align-items: center;
        justify-content: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        border: none;
        background-color: rgb(28, 28, 28);
        border-radius: 10px;
        /*misc*/
        cursor: pointer;

        &:hover {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/

            img {
                /*posicion y layout*/
                width: 0%;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .tarjeta-bloqueada__p--precio {
                /*posicion y layout*/
                bottom: 170px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .tarjeta-bloqueada__p--reclamar {
                /*posicion y layout*/
                bottom: 90px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                color: rgb(228, 228, 228);
                /*misc*/
            }
        }

        img {
            /*posicion y layout*/
            width: 60%;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            transition: ease-in-out 0.3s;
            /*misc*/
        }

        .tarjeta-bloqueada__p--precio {
            /*posicion y layout*/
            width: 50%;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 30px;
            /*modelo de caja*/
            /*tipografia*/
            font-family: $fuente-principal;
            /*visual*/
            color: white;
            border-radius: 20px;
            border: solid 3px rgb(74, 74, 74);
            background-color: #2e2e2e;
            /*misc*/
            transition: ease-in-out 0.3s;
        }

        .tarjeta-bloqueada__p--reclamar {
            /*posicion y layout*/
            width: 50%;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 10px;
            /*modelo de caja*/
            /*tipografia*/
            font-family: $fuente-principal;
            /*visual*/
            color: rgba(255, 255, 255, 0);
            /*misc*/
            transition: ease-in-out 0.3s;
        }
    }

    .tarjeta-bloqueada__p--monedas-insuficientes {
        /*posicion y layout*/
        width: 90%;
        height: 220px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        /*modelo de caja*/
        /*tipografia*/
        font-family: $fuente-principal;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
        /*visual*/
        border: rgb(97, 97, 97) 4px solid;
        background-color: rgb(28, 28, 28);
        background-image: url('../img/tarjetas//loro.png');
        background-position: center;
        background-size: 50%;
        background-repeat: no-repeat;
        color: white;
        /*misc*/
    }
}


@keyframes moveGradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";

body {
    height: 100%;
}

.lista-tarjetas__div--container {
    /*posicion y layout*/
    width: 100%;
    height: 100%;
    position: relative;
    top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: rgb(9, 9, 9);
    /*misc*/


    .lista-tarjetas__div--imagen-fondo {
        /*posicion y layout*/
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        opacity: 0.5;
        background-position: center;
        background-size: cover;
        background-image: url('../img/menu/cabecero.png');
        background-attachment: fixed;
        /*misc*/
    }


    .lista-tarjetas__div--grid-tarjetas {
        /*posicion y layout*/
        margin: 30px 0;
        display: grid;
        grid-template-columns: repeat(1, auto);
        // grid-template-rows: repeat(auto-fill, minmax(130px, 130px));
        grid-row-gap: 50px;
        grid-column-gap: 4px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }

    @media screen and (min-width: 530px){
        .lista-tarjetas__div--grid-tarjetas {
            /*posicion y layout*/
            margin-top: 30px;
            display: grid;
            grid-template-columns: repeat(2, auto);
            grid-row-gap: 50px;
            grid-column-gap: 10px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }

    @media screen and (min-width: 800px){
        .lista-tarjetas__div--grid-tarjetas {
            /*posicion y layout*/
            margin: 100px 0;
            grid-template-columns: repeat(3, auto);
            /*modelo de caja*/
            grid-row-gap: 35px;
            grid-column-gap: 15px;
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }

    @media screen and (min-width: 1100px){
        .lista-tarjetas__div--grid-tarjetas {
            /*posicion y layout*/
            grid-template-columns: repeat(4, auto);
            grid-row-gap: 50px;
            grid-column-gap: 50px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}

@media screen and (min-width: 800px){
    .lista-tarjetas__div--container {
        /*posicion y layout*/
        height: max-content;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}


.lista-tarjetas__div--container--cargador {
    /*posicion y layout*/
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: rgb(224, 224, 224);
    /*misc*/
}
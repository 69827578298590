/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";

.animacion-login__div--container {
    /*posicion y layout*/
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    overflow: hidden !important;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-image: url("../img/Index-login/diseno-3d-1c.png");
    // background-color: red;
    background-position: top 10% right 0%;
    /*misc*/
    background-repeat: repeat;
    background-position: left left;
    background-size: 600%;
    transform: scale(1);
    animation: moverImagen 100s infinite;


    .animacion-login__img--logo {
        /*posicion y layout*/
        width: 220px;
        position: absolute;
        left: 0;
        right: 0;
        top: 120px;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        background-color: white;
        border-radius: 10px;
        /*misc*/
    }

    .animacion-login__img--barco {
        /*posicion y layout*/
        position: absolute;
        z-index: 10;
        left: 0;
        right: 0;
        top: 220px;
        width: 200px;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        /*misc*/
    }

    .animacion-login__img--mar {
        /*posicion y layout*/
        position: absolute;
        width: 3000px;
        left: -1000px;
        bottom: 0;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}


@keyframes moverImagen {
    0% {
      background-position: 0% 0; /* Comienza desde la izquierda */
    }
    100% {
      background-position: 100% 0; /* Se desplaza hasta la derecha del viewport */
    }
}
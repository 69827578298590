/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";


.nav-bar-desktop__div--container {
    /*posicion y layout*/
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    box-shadow: 0px 0px 13px #000000;
    /*misc*/

    .nav-bar-desktop__div--fondo-animado {
        /*posicion y layout*/
        width: 100%;
        height: 80px;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        overflow: hidden !important;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-image: url("../img//globales/fondo-animado.png");
        background-position: top 10% right 0%;
        /*misc*/
        background-repeat: repeat;
        background-position: left top;
        background-size: 150%;
        transform: scale(1);
        animation: animacion-fondo-menu 120s infinite;
    }

    .nav-bar-desktop__div--paquete-opciones {
        /*posicion y layout*/
        width: 100%;
        position: relative;
        z-index: 10;
        display: flex;

        height: 80px;
        justify-content: space-between;
        /*modelo de caja*/
        padding: 0 100px;
        /*tipografia*/
        /*visual*/
        /*misc*/

        .nav-bar-desktop__img--menu-principal {
            /*posicion y layout*/
            width: 60px;
            position: relative;
            top: 10px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            border-radius: 50%;
            background-color: $color-secundario;
            /*misc*/
            transition: ease-in-out 0.3s;
            cursor: pointer;
        }

        // .nav-bar-desktop__img--menu-principal:hover {
        //     /*posicion y layout*/
        //     /*modelo de caja*/
        //     /*tipografia*/
        //     /*visual*/
        //     /*misc*/
        //     // transform: scale(1.3);
        // }

        .nav-bar-desktop__div--container--cajon-animado {
            /*posicion y layout*/
            position: relative;
            top: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            // top: 80px; //estilo tr
            width: 200px; //estilo tr
            height: 70px;
            padding: 1px;
            transform-origin: top;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            background-position: center;
            background-size: cover;
            $border: 3px;
            border-radius: 20px;
            box-sizing: border-box;
            background-clip: padding-box; /* !importanté */
            border: solid $border transparent;  /* !importanté */
            border-radius: 1em;
            background-color: $color-secundario;
            /*misc*/
            transition: ease-in-out 0.5s;

            &:before {
                content: '';
                position: absolute;
                top: 0; right: 0; bottom: 0; left: 0;
                z-index: -1;
                margin: -$border;
                border-radius: inherit; /* !importanté */
                background-size: 200% 200%;
                background: linear-gradient(to right, #00CED1, #FFA500);
                animation: gradientAnimation 2s linear infinite;
            }

                .nav-bar-desktop__div--nombre-puntos {
                    /*posicion y layout*/
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    /*modelo de caja*/
                    /*tipografia*/
                    font-family: $fuente-principal;
                    /*visual*/
                    /*misc*/

                    .nav-bar-desktop__div--usuario {
                        /*posicion y layout*/
                        /*modelo de caja*/
                        margin: 15px auto;
                        padding: 1px 15px;
                        /*tipografia*/
                        font-weight: bold;
                        text-align: center;
                        /*visual*/
                        color: #3e3e3e;
                        border: solid $color-blanco-fuente 2px;
                        border-radius: 20px;
                        background-color: $color-secundario;
                        /*misc*/
                    }

                    .nav-bar-desktop__div--paquete-puntos {
                        /*posicion y layout*/
                        position: relative;
                        bottom: 10px;
                        width: 50%;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        /*modelo de caja*/
                        margin: auto;
                        /*tipografia*/
                        /*visual*/
                        color:#3e3e3e;
                        border-radius: 20px;
                        background-color: $color-blanco-fuente;
                        /*misc*/
                    }
                }

                .nav-bar-desktop__div--container-botones-salir-premios {
                    /*posicion y layout*/
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 0;
                    height: 0;
                    overflow: hidden;
                    background-color: rgba(62, 62, 62, 0.61);
                    /*modelo de caja*/
                    margin: auto;
                    /*tipografia*/
                    /*visual*/
                    border-radius: 20px;
                    /*misc*/
                    transition: ease-in-out 0.3s;

                    .nav-bar-desktop__img--premios {
                        /*posicion y layout*/
                        width: 45px;
                        position: relative;
                        top: 2px;
                        /*modelo de caja*/
                        /*tipografia*/
                        /*visual*/
                        background-color: $color-secundario;
                        border-radius: 100%;
                        /*misc*/
                    }

                    .nav-bar-desktop__button--cerrar-container {
                        /*posicion y layout*/
                        width: 45px;
                        height: 45px;
                        display: flex;
                        place-items: center;
                        /*modelo de caja*/
                        /*tipografia*/
                        /*visual*/
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        /*misc*/

                        .nav-bar-desktop__img--cerrar-sesion {
                            /*posicion y layout*/
                            width: 45px;
                            /*modelo de caja*/
                            /*tipografia*/
                            /*visual*/
                            background-color: $color-secundario;
                            border-radius: 100%;
                            /*misc*/
                        }
                    }


                }
        }
    }
}

@keyframes animacion-fondo-menu{
    0% {background-position: 500px 500px}
    50%{background-position: 100px 100px}
    100%{background-position: 500px 500px}
}


@keyframes gradientAnimation {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
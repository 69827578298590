@import "_variables";

.container-3d {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.container {
  width: 100%;
  max-width: 800px;
}

/* Atropos web component */
.atropos-component {
  display: block;
}
.atropos-component .atropos-inner {
  border-radius: 1rem;
}
.atropos-component img {
  position: absolute;
  left: -5%;
  top: -5%;
  width: 110%;
  height: 110%;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
  z-index: 1;
  transform-style: preserve-3d;
  pointer-events: none;
  max-width: none;
}
.atropos-component img.atropos-spacer {
  position: relative;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  visibility: hidden;
}
.atropos-component .atropos-button-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
}
.atropos-component .atropos-button-wrap a {
  color: inherit;
  text-decoration: none;
}
.atropos-component .atropos-shadow {
  filter: blur(50px);
  opacity: .25;
}
.atropos-component .atropos-active .atropos-shadow {
  opacity: 1 !important;
}

.index__div--container {
  /*posicion y layout*/
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-image: url('../img//Index-login//fondo-index.png');
  background-position: center;
  background-size: cover;
  /*misc*/
}

.index__div--container-button-submit {
    position: absolute;
    top: 350px;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    // background-color: red;
}

.contenedor-fields {
  /*posicion y layout*/
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  top: 200px;
  left: 0;
  right: 0;
  width: 80%;
  height: 50px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.logo-galileo {
  /*posicion y layout*/
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 60px;
  left: 0;
  right: 0;
  width: 80%;
  height: 50px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/

    .imagen-logoPrincipal {
      /*posicion y layout*/
      width: 300px;
      height: 130px;
      /*modelo de caja*/
      /*tipografia*/
      /*visual*/
      background-color: rgba(220, 220, 220, 0.449);
      background-image: url('../img/Index-login/logo-galileo.png');
      border: solid 2px $color-secundario;
      background-size: 100%;
      background-position: center;
      background-repeat: none;
      border-radius: 20px;
      /*misc*/
    }
}

.index__field--input {
  /*posicion y layout*/
  width: 250px;
  height: 30px;
  border-radius: 20px;
  text-align: center;
  /*modelo de caja*/
  /*tipografia*/
  font-family: $fuente-principal;
  /*visual*/
  /*misc*/
}

.index__field--input-usuario {
  /*posicion y layout*/
  border: solid 3px $color-primario;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.index__field--input-contrasena {
  /*posicion y layout*/
  border: solid 3px $color-secundario;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}


.index__div--container-button-submit {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/

    button {
      /*posicion y layout*/
      width: 300px;
      height: 30px;
      /*modelo de caja*/
      /*tipografia*/
      font-family: $fuente-principal;
      font-weight: bold;
      /*visual*/
      border: none;
      border-radius: 20px;
      background-color: $color-secundario;
      color: white;
      /*misc*/
      cursor: pointer;
      box-shadow: 1px 1px 28px 6px rgba(0,0,0,0.75);
      -webkit-box-shadow: 1px 1px 28px 6px rgba(0,0,0,0.75);
      -moz-box-shadow: 1px 1px 28px 6px rgba(0,0,0,0.75);
      transition: ease-in-out 0.3s;
    }

    button:hover {
      /*posicion y layout*/
      /*modelo de caja*/
      /*tipografia*/
      /*visual*/
      background-color: white;
      color: $color-secundario;
      border: $color-secundario 2px solid;
      /*misc*/
    }
}


.index__h4--error {
  /*posicion y layout*/
  position: absolute;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
  /*modelo de caja*/
  /*tipografia*/
  font-family: $fuente-principal;
  text-align: center;
  /*visual*/
  color: rgb(255, 0, 0);
  border-radius: 20px;
  background-color: rgb(255, 121, 121);
  /*misc*/
}

.index__h4--usuario {
  /*posicion y layout*/
  bottom: 90px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}





//Loader
.loader {
  font-size: 10px;
  margin: 30% auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: $color-secundario;
  background: -moz-linear-gradient(left, $color-secundario 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, $color-secundario 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, $color-secundario 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, $color-secundario 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, $color-secundario 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  bottom: 150px;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
}
.loader:before {
  width: 50%;
  height: 50%;
  background: linear-gradient(blue, $color-secundario);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
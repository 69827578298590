/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";


.cargaInicial__div--container {
    /*posicion y layout*/
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    overflow: hidden;
    /*misc*/

    .cargaInicial__img--fondo-degradado {
        /*posicion y layout*/
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        opacity: 1;
        background-image: url("../img/carga-pagina/fondo.png");
        /*misc*/
    }

    .cargaInicial__img--fondo-degradado::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.229); /* Color negro con opacidad (0.0 - 1.0) */
    }

    .cargaInicial__img--fondo-mar {
        /*posicion y layout*/
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        overflow: hidden;
        background-image: url("../img/carga-pagina/fondo-mar.png");
        background-position: 45%;
        background-size: cover;
        opacity: 0.8;
        /*misc*/
    }

    @media screen and (min-width: 800px){
        .cargaInicial__img--fondo-mar {
            /*posicion y layout*/
            background-position: center;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }

    .cargaInicial__img--logo-aplicativo {
        /*posicion y layout*/
        width: 200px;
        position: absolute;
        left: 0;
        right: 0;
        top: 70px;
        /*modelo de caja*/
        margin: auto;
        /*tipografia*/
        /*visual*/
        /*misc*/
    }

    @media screen and (min-width: 800px){
        .cargaInicial__img--logo-aplicativo {
            /*posicion y layout*/
            width: 350px;
            top: 10px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }

    .cargaInicial__div--animacion {
        /*posicion y layout*/
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0px;
        left: 0px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        overflow: hidden;
        background-image: url("../img/carga-pagina/animacion-1.png");
        background-position: top 10% right 0%;
        /*misc*/
        background-repeat: repeat;
        background-position: left top;
        transform: scale(1);
        transition: ease-in-out 5s;
    }

    .cargaInicial__div--animacion1 {
        /*posicion y layout*/
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        overflow: hidden;
        background-size: 2000px;
        background-image: url("../img/carga-pagina/animacion-1.png");
        /*misc*/
        animation: animacion-fondo-menu1 70s infinite;
    }

    .cargaInicial__div--animacion2 {
        /*posicion y layout*/
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        overflow: hidden;
        background-position: rotate(120deg);
        background-size: 1200px;
        background-image: url("../img/carga-pagina/animacion-3.png");
        /*misc*/
        animation: animacion-fondo-menu2 100s infinite;
    }

    .cargaInicial__div--animacion3 {
        /*posicion y layout*/
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        overflow: hidden;
        background-size: 1500px;
        background-image: url("../img/carga-pagina/animacion-3.png");
        /*misc*/
        animation: animacion-fondo-menu3 150s infinite;
    }

    .cargaInicial__div--cargando-container {
        /*posicion y layout*/
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        /*tipografia*/
        font-family: $fuente-principal;
        font-weight: bold;
        /*visual*/
        background-color: $color-blanco-fuente;
        border-radius: 10px;
        padding: 5px 25px;
        /*misc*/

        p {
            /*posicion y layout*/
            position: relative;
            /*modelo de caja*/
            /*tipografia*/
            font-size: 20px;
            /*visual*/
            /*misc*/
            transition: ease-in-out 1s;
        }

        .primeras {
                /*posicion y layout*/
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
                animation: animacionLetras1 3s infinite;
        }

        .segundas {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
            animation: animacionLetras2 3s infinite;
        }
    }
}


@keyframes animacion-fondo-menu1{
    0% {background-position: center center; }
    50%{background-position: center bottom; }
    100%{background-position: center center; }
}


@keyframes animacion-fondo-menu2 {
    0% {background-position: -900px 800px}
    50% {background-position: -900px 900px}
    100% {background-position: 900px -900px}
}


@keyframes animacion-fondo-menu3{
    0% {background-position: -400px -400px}
    50%{background-position: 100px 100px}
    100%{background-position: -300px -300px}
}

@keyframes animacionLetras1{
    0% {bottom: 0; color: $color-primario;}
    25% {bottom: 0; color: $color-primario;}
    50%{bottom: 10px; color: $color-primario;}
    75%{bottom: 0px; color: $color-secundario;}
    100%{bottom: 0px; color: $color-secundario;}
}

@keyframes animacionLetras2{
    0% {bottom: 0; color: $color-secundario;}
    25% {bottom: 0; color: $color-secundario;}
    50%{bottom: -10px; color: $color-secundario;}
    75%{bottom: 0px; color: $color-primario;}
    100%{bottom: 0px; color: $color-primario;}
}


@media screen and (min-width: 800px){
    .cargaInicial__div--container {
        /*posicion y layout*/
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        .cargaInicial__div--cargando-container {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            padding: 10px 50px;
            /*misc*/

            p {
                /*posicion y layout*/
                /*modelo de caja*/
                /*tipografia*/
                font-size: 35px;
                /*visual*/
                /*misc*/
            }
        }
    }
}
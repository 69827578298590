/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";

.tarjeta-desbloqueada__div--container {
    /*posicion y layout*/
    position: relative;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/

    .tarjeta-desbloqueada__div--container--animacion {
        /*posicion y layout*/
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
        pointer-events: none;

        .tarjeta-desbloqueada__div--animacion-desbloqueo {
            /*posicion y layout*/
            width: 100%;
            height: 100%;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            // opacity: 0.5;
            /*misc*/
            pointer-events: none;
        }
    }



    .tarjeta-desbloqueada__img {
        /*posicion y layout*/
        position: relative;
        width: 250px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }

    .tarjeta-desbloqueada__img--personaje {
        /*posicion y layout*/
        position: absolute;
        top: 0;
        left: 0;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }

    .tarjeta-desbloqueada__div--container-nombre {
        /*posicion y layout*/
        position: absolute;
        bottom: 10px;
        width: 100%;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        img {
            /*posicion y layout*/
            position: relative;
            width: 100%;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }

        p {
            /*posicion y layout*/
            width: 100px;
            position: absolute;
            bottom: 75px;
            left: 0;
            right: 0;
            margin: auto;
            /*modelo de caja*/
            text-align: center;
            /*tipografia*/
            font-size: 18px;
            font-family: $fuente-pirata;
            /*visual*/
            color: rgb(32, 32, 32);
            background-image: url('../img/tarjetas/cinta.png');
            border-radius: 10px;
            /*misc*/
        }
    }


}
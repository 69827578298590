/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";

.index-movil__div--container {
        /*posicion y layout*/
        width: 100%;
        min-height: 850px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-image: url("../img/Navbar/animacion\ intro-09.png");
        background-position: top 10% right 0%;
        /*misc*/
        background-repeat: repeat;
        background-position: left top;
        background-size: 300%;
        transform: scale(1);
        animation: animacion-fondo-menu 120s infinite;
}

.index-movil__div--container-logo {
    /*posicion y layout*/
    width: 100%;
    position: relative;
    top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/

    img {
        /*posicion y layout*/
        width: 220px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-color: white;
        border-radius: 10px;
        /*misc*/
        box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
    }
}

.index-movil__div--inputs-container {
    /*posicion y layout*/
    width: 90%;
    display: flex;
    position: relative;
    top: 150px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*modelo de caja*/
    padding: 0px 10px;
    margin: auto;
    /*tipografia*/
    /*visual*/
    border-radius: 10px;
    border: solid 1px $color-secundario;
    // background-color: white;
    backdrop-filter: blur(3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /*misc*/
}


@media screen and (min-width: 370px){
    .index-movil__div--inputs-container {
        /*posicion y layout*/
        width: 300px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }
}


.index-movil__field--input-usuario {
    /*posicion y layout*/
    width: 95%;
    height: 30px;
    margin-bottom: 10px;
    /*modelo de caja*/
    /*tipografia*/
    text-align: center;
    font-family: $fuente-principal;
    /*visual*/
    border-radius: 25px;
    border: solid 3px $color-secundario;
    /*misc*/
}

.index-movil__field--input-contrasena {
    /*posicion y layout*/
    width: 95%;
    height: 30px;
    /*modelo de caja*/
    /*tipografia*/
    text-align: center;
    font-family: $fuente-principal;
    /*visual*/
    border-radius: 25px;
    border: solid 3px $color-secundario;
    /*misc*/
}

.index-movil__img--imagen-usuario-container {
    /*posicion y layout*/
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/

    .index-movil__img--imagen-usuario {
        /*posicion y layout*/
        width: 30px;
        /*modelo de caja*/
        margin: auto;
        padding: 10px;
        /*tipografia*/
        /*visual*/
        border-radius: 100%;
        background-color: white;
        /*misc*/
    }
}


.index-movil__div--container-button-submit {
    /*posicion y layout*/
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/

    button {
        /*posicion y layout*/
        width: 200px;
        height: 30px;
        /*modelo de caja*/
        /*tipografia*/
        font-family: $fuente-principal;
        text-align: center;
        font-weight: bold;
        /*visual*/
        border: none;
        color: white;
        border-radius: 15px;
        background-color: $color-secundario;
        /*misc*/
    }
}


.index-movil__h4--error {
    /*posicion y layout*/
    width: 80%;
    position: absolute;
    top: 560px;
    /*modelo de caja*/
    padding: 5px 10px;
    margin: auto;
    left: 0;
    right: 0;
    /*tipografia*/
    font-size: 15px;
    text-align: center;
    font-family: $fuente-principal;
    color: red;
    /*visual*/
    background-color: rgb(255, 105, 105);
    border-radius: 25px;
    /*misc*/
}

.index-movil__h4--contrasena {
    /*posicion y layout*/
    top: 595px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}

.index-movil__div--contenedor-loader {
    /*posicion y layout*/
    position: relative;
    top: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";

.juegoDesbloqueado__div--container {
    /*posicion y layout*/
    position: relative;
    z-index: 10;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/

    .juegoDesbloqueado__button {
        /*posicion y layout*/
        width: auto;
        height: 100%;
        border: none;
        padding: 0;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        .juegoDesbloqueado__img {
            /*posicion y layout*/
            position: relative;
            z-index: 10;
            width: 100%;
            height: 100%;
            object-fit: cover;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            border-radius: 0%;
            /*misc*/
            cursor: pointer;
            transition: ease-in-out 0.3s;

            &:hover{
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            border-radius: 100%;
            /*misc*/

            }
        }

        .juegoDesbloqueado__img--cinta {
            /*posicion y layout*/
            position: absolute;
            z-index: 20;
            bottom: 0px;
            left: 0;
            right: 0;
            margin: auto;
            width: 100px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }


        .juegoDesbloqueado__p--nombre {
            /*posicion y layout*/
            width: 80%;
            position: absolute;
            z-index: 21;
            bottom: 24.5px;
            left: 0;
            right: 0;
            margin: auto;
            /*modelo de caja*/
            /*tipografia*/
            font-size: 13px;
            font-family: $fuente-pirata;
            font-weight: bold;
            /*visual*/
            color: rgb(34, 34, 34);
            /*misc*/
        }

        @media screen and (min-width: 800px){
            .juegoDesbloqueado__img--cinta {
                /*posicion y layout*/
                position: absolute;
                z-index: 20;
                bottom: 0px;
                left: 0;
                right: 0;
                margin: auto;
                width: 170px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

            .juegoDesbloqueado__p--nombre {
                /*posicion y layout*/
                bottom: 42px;
                /*modelo de caja*/
                /*tipografia*/
                font-size: 20px;
                /*visual*/
                /*misc*/
            }
        }
    }
}


$color-primario: #FFA500;
$color-secundario: #00CED1;
$color-blanco-fuente: #E4E4E4;
$color-negro-fuente: #313131;
$fuente-principal: 'Poppins', sans-serif;
$fuente-pirata: "pirata one", sans-serif;
$color-negativo: #D60000;
$color-positivo: #00BB07;

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/

.separador__div--container {
    /*posicion y layout*/
    position: relative;
    top: 80px;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    /*modelo de caja*/
    /*tipografia*/
    color: #ffffff;
    /*visual*/
    /*misc*/

    .separador__img--separador {
        /*posicion y layout*/
        margin: auto;
        z-index: 3;
        width: 110px;
        overflow: visible;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.77));
        /*misc*/
    }

    @media screen and (min-width: 500px){
        .separador__img--separador {
            /*posicion y layout*/
            position: absolute;
            width: 150px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";

.navbar-movil__div--container {
    /*posicion y layout*/
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: $color-primario;
    box-shadow: 0px 0px 13px #000000;
    /*misc*/

    .navbar-movil__div--fondo-animado {
        /*posicion y layout*/
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        overflow: hidden !important;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-image: url("../img/globales/fondo-animado.png");
        background-position: top 10% right 0%;
        /*misc*/
        background-repeat: repeat;
        background-position: left top;
        background-size: 1500px;
        transform: scale(1);
        animation: animacion-fondo-menu 100s infinite;
    }

    .navbar-movil__button--hamburguesa {
        /*posicion y layout*/
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        /*modelo de caja*/
        border-radius: 10px;
        padding: 5px;
        /*tipografia*/
        /*visual*/
        border: none;
        background-color: $color-secundario;
        /*misc*/

        .navbar-movil__img--hamburguesa {
            /*posicion y layout*/
            width: 30px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            opacity: 1;
            /*misc*/
            transition: ease-in-out 0.5s;
        }

        .navbar-movil__img--cerrar {
            /*posicion y layout*/
            width: 30px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            opacity: 1;
            /*misc*/
            transition: ease-in-out 0.5s;
        }
    }


    .navbar-movil__div--container-usuario-puntos {
        /*posicion y layout*/
        width: 0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        z-index: 15;
        /*modelo de caja*/
        /*tipografia*/
        font-size: 14px;
        font-family: $fuente-principal;
        /*visual*/
        border-radius: 50px;
        background-color: $color-secundario;
        /*misc*/
        transition: ease-in-out 0.5s;

        .navbar-movil__img--cofre {
            /*posicion y layout*/
            width: 30px;
            /*modelo de caja*/
            margin: 10px;
            /*tipografia*/
            /*visual*/
            /*misc*/
        }

        .navbar-movil__div--paquete-puntos {
            /*posicion y layout*/
            position: relative;
            right: 15px;
            height: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            background-color: rgba(255, 255, 255, 0.762);
            /*modelo de caja*/
            margin: 5px;
            box-sizing: border-box;
            /*tipografia*/
            /*visual*/
            /*misc*/

            .navbar-movil__p--puntos {
                /*posicion y layout*/
                display: flex;
                align-items: center;
                justify-content: center;
                /*modelo de caja*/
                margin: 5px;
                /*tipografia*/
                /*visual*/
                /*misc*/
            }

        }

        .navbar-movil__p--usuario {
            /*posicion y layout*/
            /*modelo de caja*/
            margin: 10px;
            /*tipografia*/
            font-weight: bold;
            /*visual*/
            /*misc*/
        }
    }
}

@keyframes animacion-fondo-menu{
    0% {background-position: 500px 500px}
    50%{background-position: 100px 100px}
    100%{background-position: 500px 500px}
}


.navbar-movil__div--container-opciones {
    /*posicion y layout*/
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 80px;
    width: 100%;
    height: 0vh;
    overflow: hidden;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: rgb(230, 230, 230);
    /*misc*/
    transition: ease-in-out 1s;

    .navbar-movil__div--grid {
        /*posicion y layout*/
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 10px;
        width: 300px;
        height: 480px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        .navbar-movil__button-opcion {
            /*posicion y layout*/
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /*modelo de caja*/
            /*tipografia*/
            font-family: $fuente-principal;
            border-radius: 15px;
            text-decoration: none;
            /*visual*/
            border: none;
            /*misc*/

            a {
                text-decoration: none;
            }

            img {
                /*posicion y layout*/
                width: 100px;
                position: relative;
                z-index: 5;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }

        .navbar-movil__button-opcion-azul {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            background-color: $color-secundario;
            /*misc*/
        }

        .navbar-movil__button-opcion-naranja {
            /*posicion y layout*/
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            background-color: $color-primario;
            /*misc*/
        }
    }
}


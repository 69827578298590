

.nav-bar__div--container {
    /*posicion y layout*/
    position: relative;
    z-index: 15;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
}
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";


.transicion__div--container {
    /*posicion y layout*/
    position: relative;
    z-index: 10;
    width: 250px;
    height: 380px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: white;
    /*misc*/
}
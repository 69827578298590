/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";


.juegoBloqueado__div--container {
    /*posicion y layout*/
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
    /*modelo de caja*/
    margin: 0;
    /*tipografia*/
    /*visual*/
    background-color: rgb(0, 0, 0);
    /*misc*/

    .juegoBloqueado__img {
        /*posicion y layout*/
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
    }

    .juegoBloqueado__img-candado {
        /*posicion y layout*/
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/
        transition: transform ease-in-out 1s;
    }
}


.juegoBloqueado__div--container:hover .juegoBloqueado__img-candado {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
    transform: translate(-50%, -50%) rotate(360deg);
    animation: girarInfinito 1.4s linear infinite;
    }



@keyframes girarInfinito {
    0% {
        transform: translate(-50%, -50%) rotate(0deg) scale(1.2);
    }
    25% {
        transform: translate(-50%, -50%) rotate(80deg) scale(1);
    }
    50% {
        transform: translate(-50%, -50%) rotate(0deg) scale(1.2);
    }
    75% {
        transform: translate(-50%, -50%) rotate(-80deg)scale(1);
    }
    100% {
        transform: translate(-50%, -50%) rotate(0deg) scale(1.2);
    }
}
@charset "UTF-8";
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.cargaInicial__div--container {
  /*posicion y layout*/
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  overflow: hidden;
  /*misc*/
}
.cargaInicial__div--container .cargaInicial__img--fondo-degradado {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  opacity: 1;
  background-image: url("../img/carga-pagina/fondo.png");
  /*misc*/
}
.cargaInicial__div--container .cargaInicial__img--fondo-degradado::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.229); /* Color negro con opacidad (0.0 - 1.0) */
}
.cargaInicial__div--container .cargaInicial__img--fondo-mar {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  overflow: hidden;
  background-image: url("../img/carga-pagina/fondo-mar.png");
  background-position: 45%;
  background-size: cover;
  opacity: 0.8;
  /*misc*/
}
@media screen and (min-width: 800px) {
  .cargaInicial__div--container .cargaInicial__img--fondo-mar {
    /*posicion y layout*/
    background-position: center;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.cargaInicial__div--container .cargaInicial__img--logo-aplicativo {
  /*posicion y layout*/
  width: 200px;
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
@media screen and (min-width: 800px) {
  .cargaInicial__div--container .cargaInicial__img--logo-aplicativo {
    /*posicion y layout*/
    width: 350px;
    top: 10px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.cargaInicial__div--container .cargaInicial__div--animacion {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  overflow: hidden;
  background-image: url("../img/carga-pagina/animacion-1.png");
  background-position: top 10% right 0%;
  /*misc*/
  background-repeat: repeat;
  background-position: left top;
  transform: scale(1);
  transition: ease-in-out 5s;
}
.cargaInicial__div--container .cargaInicial__div--animacion1 {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  overflow: hidden;
  background-size: 2000px;
  background-image: url("../img/carga-pagina/animacion-1.png");
  /*misc*/
  animation: animacion-fondo-menu1 70s infinite;
}
.cargaInicial__div--container .cargaInicial__div--animacion2 {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  overflow: hidden;
  background-position: rotate(120deg);
  background-size: 1200px;
  background-image: url("../img/carga-pagina/animacion-3.png");
  /*misc*/
  animation: animacion-fondo-menu2 100s infinite;
}
.cargaInicial__div--container .cargaInicial__div--animacion3 {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  overflow: hidden;
  background-size: 1500px;
  background-image: url("../img/carga-pagina/animacion-3.png");
  /*misc*/
  animation: animacion-fondo-menu3 150s infinite;
}
.cargaInicial__div--container .cargaInicial__div--cargando-container {
  /*posicion y layout*/
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  /*visual*/
  background-color: #E4E4E4;
  border-radius: 10px;
  padding: 5px 25px;
  /*misc*/
}
.cargaInicial__div--container .cargaInicial__div--cargando-container p {
  /*posicion y layout*/
  position: relative;
  /*modelo de caja*/
  /*tipografia*/
  font-size: 20px;
  /*visual*/
  /*misc*/
  transition: ease-in-out 1s;
}
.cargaInicial__div--container .cargaInicial__div--cargando-container .primeras {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  animation: animacionLetras1 3s infinite;
}
.cargaInicial__div--container .cargaInicial__div--cargando-container .segundas {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  animation: animacionLetras2 3s infinite;
}

@keyframes animacion-fondo-menu1 {
  0% {
    background-position: center center;
  }
  50% {
    background-position: center bottom;
  }
  100% {
    background-position: center center;
  }
}
@keyframes animacion-fondo-menu2 {
  0% {
    background-position: -900px 800px;
  }
  50% {
    background-position: -900px 900px;
  }
  100% {
    background-position: 900px -900px;
  }
}
@keyframes animacion-fondo-menu3 {
  0% {
    background-position: -400px -400px;
  }
  50% {
    background-position: 100px 100px;
  }
  100% {
    background-position: -300px -300px;
  }
}
@keyframes animacionLetras1 {
  0% {
    bottom: 0;
    color: #FFA500;
  }
  25% {
    bottom: 0;
    color: #FFA500;
  }
  50% {
    bottom: 10px;
    color: #FFA500;
  }
  75% {
    bottom: 0px;
    color: #00CED1;
  }
  100% {
    bottom: 0px;
    color: #00CED1;
  }
}
@keyframes animacionLetras2 {
  0% {
    bottom: 0;
    color: #00CED1;
  }
  25% {
    bottom: 0;
    color: #00CED1;
  }
  50% {
    bottom: -10px;
    color: #00CED1;
  }
  75% {
    bottom: 0px;
    color: #FFA500;
  }
  100% {
    bottom: 0px;
    color: #FFA500;
  }
}
@media screen and (min-width: 800px) {
  .cargaInicial__div--container {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .cargaInicial__div--container .cargaInicial__div--cargando-container {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    padding: 10px 50px;
    /*misc*/
  }
  .cargaInicial__div--container .cargaInicial__div--cargando-container p {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    font-size: 35px;
    /*visual*/
    /*misc*/
  }
}
.container-3d {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 800px;
}

/* Atropos web component */
.atropos-component {
  display: block;
}

.atropos-component .atropos-inner {
  border-radius: 1rem;
}

.atropos-component img {
  position: absolute;
  left: -5%;
  top: -5%;
  width: 110%;
  height: 110%;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
  z-index: 1;
  transform-style: preserve-3d;
  pointer-events: none;
  max-width: none;
}

.atropos-component img.atropos-spacer {
  position: relative;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  visibility: hidden;
}

.atropos-component .atropos-button-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
}

.atropos-component .atropos-button-wrap a {
  color: inherit;
  text-decoration: none;
}

.atropos-component .atropos-shadow {
  filter: blur(50px);
  opacity: 0.25;
}

.atropos-component .atropos-active .atropos-shadow {
  opacity: 1 !important;
}

.index__div--container {
  /*posicion y layout*/
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-image: url("../img//Index-login//fondo-index.png");
  background-position: center;
  background-size: cover;
  /*misc*/
}

.index__div--container-button-submit {
  position: absolute;
  top: 350px;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.contenedor-fields {
  /*posicion y layout*/
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  top: 200px;
  left: 0;
  right: 0;
  width: 80%;
  height: 50px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.logo-galileo {
  /*posicion y layout*/
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 60px;
  left: 0;
  right: 0;
  width: 80%;
  height: 50px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.logo-galileo .imagen-logoPrincipal {
  /*posicion y layout*/
  width: 300px;
  height: 130px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: rgba(220, 220, 220, 0.449);
  background-image: url("../img/Index-login/logo-galileo.png");
  border: solid 2px #00CED1;
  background-size: 100%;
  background-position: center;
  background-repeat: none;
  border-radius: 20px;
  /*misc*/
}

.index__field--input {
  /*posicion y layout*/
  width: 250px;
  height: 30px;
  border-radius: 20px;
  text-align: center;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  /*visual*/
  /*misc*/
}

.index__field--input-usuario {
  /*posicion y layout*/
  border: solid 3px #FFA500;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.index__field--input-contrasena {
  /*posicion y layout*/
  border: solid 3px #00CED1;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.index__div--container-button-submit {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.index__div--container-button-submit button {
  /*posicion y layout*/
  width: 300px;
  height: 30px;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  /*visual*/
  border: none;
  border-radius: 20px;
  background-color: #00CED1;
  color: white;
  /*misc*/
  cursor: pointer;
  box-shadow: 1px 1px 28px 6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 1px 28px 6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 28px 6px rgba(0, 0, 0, 0.75);
  transition: ease-in-out 0.3s;
}
.index__div--container-button-submit button:hover {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: white;
  color: #00CED1;
  border: #00CED1 2px solid;
  /*misc*/
}

.index__h4--error {
  /*posicion y layout*/
  position: absolute;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  text-align: center;
  /*visual*/
  color: rgb(255, 0, 0);
  border-radius: 20px;
  background-color: rgb(255, 121, 121);
  /*misc*/
}

.index__h4--usuario {
  /*posicion y layout*/
  bottom: 90px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.loader {
  font-size: 10px;
  margin: 30% auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #00CED1;
  background: -moz-linear-gradient(left, #00CED1 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #00CED1 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #00CED1 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #00CED1 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #00CED1 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  bottom: 150px;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
}

.loader:before {
  width: 50%;
  height: 50%;
  background: linear-gradient(blue, #00CED1);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.nav-bar__div--container {
  /*posicion y layout*/
  position: relative;
  z-index: 15;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.nav-bar-desktop__div--container {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  box-shadow: 0px 0px 13px #000000;
  /*misc*/
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--fondo-animado {
  /*posicion y layout*/
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  overflow: hidden !important;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-image: url("../img//globales/fondo-animado.png");
  background-position: top 10% right 0%;
  /*misc*/
  background-repeat: repeat;
  background-position: left top;
  background-size: 150%;
  transform: scale(1);
  animation: animacion-fondo-menu 120s infinite;
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones {
  /*posicion y layout*/
  width: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  height: 80px;
  justify-content: space-between;
  /*modelo de caja*/
  padding: 0 100px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones .nav-bar-desktop__img--menu-principal {
  /*posicion y layout*/
  width: 60px;
  position: relative;
  top: 10px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border-radius: 50%;
  background-color: #00CED1;
  /*misc*/
  transition: ease-in-out 0.3s;
  cursor: pointer;
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones .nav-bar-desktop__div--container--cajon-animado {
  /*posicion y layout*/
  position: relative;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 200px;
  height: 70px;
  padding: 1px;
  transform-origin: top;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  box-sizing: border-box;
  background-clip: padding-box; /* !importanté */
  border: solid 3px transparent; /* !importanté */
  border-radius: 1em;
  background-color: #00CED1;
  /*misc*/
  transition: ease-in-out 0.5s;
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones .nav-bar-desktop__div--container--cajon-animado:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -3px;
  border-radius: inherit; /* !importanté */
  background-size: 200% 200%;
  background: linear-gradient(to right, #00CED1, #FFA500);
  animation: gradientAnimation 2s linear infinite;
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones .nav-bar-desktop__div--container--cajon-animado .nav-bar-desktop__div--nombre-puntos {
  /*posicion y layout*/
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  /*visual*/
  /*misc*/
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones .nav-bar-desktop__div--container--cajon-animado .nav-bar-desktop__div--nombre-puntos .nav-bar-desktop__div--usuario {
  /*posicion y layout*/
  /*modelo de caja*/
  margin: 15px auto;
  padding: 1px 15px;
  /*tipografia*/
  font-weight: bold;
  text-align: center;
  /*visual*/
  color: #3e3e3e;
  border: solid #E4E4E4 2px;
  border-radius: 20px;
  background-color: #00CED1;
  /*misc*/
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones .nav-bar-desktop__div--container--cajon-animado .nav-bar-desktop__div--nombre-puntos .nav-bar-desktop__div--paquete-puntos {
  /*posicion y layout*/
  position: relative;
  bottom: 10px;
  width: 50%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  color: #3e3e3e;
  border-radius: 20px;
  background-color: #E4E4E4;
  /*misc*/
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones .nav-bar-desktop__div--container--cajon-animado .nav-bar-desktop__div--container-botones-salir-premios {
  /*posicion y layout*/
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  overflow: hidden;
  background-color: rgba(62, 62, 62, 0.61);
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  border-radius: 20px;
  /*misc*/
  transition: ease-in-out 0.3s;
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones .nav-bar-desktop__div--container--cajon-animado .nav-bar-desktop__div--container-botones-salir-premios .nav-bar-desktop__img--premios {
  /*posicion y layout*/
  width: 45px;
  position: relative;
  top: 2px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #00CED1;
  border-radius: 100%;
  /*misc*/
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones .nav-bar-desktop__div--container--cajon-animado .nav-bar-desktop__div--container-botones-salir-premios .nav-bar-desktop__button--cerrar-container {
  /*posicion y layout*/
  width: 45px;
  height: 45px;
  display: flex;
  place-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: transparent;
  border: none;
  cursor: pointer;
  /*misc*/
}
.nav-bar-desktop__div--container .nav-bar-desktop__div--paquete-opciones .nav-bar-desktop__div--container--cajon-animado .nav-bar-desktop__div--container-botones-salir-premios .nav-bar-desktop__button--cerrar-container .nav-bar-desktop__img--cerrar-sesion {
  /*posicion y layout*/
  width: 45px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #00CED1;
  border-radius: 100%;
  /*misc*/
}

@keyframes animacion-fondo-menu {
  0% {
    background-position: 500px 500px;
  }
  50% {
    background-position: 100px 100px;
  }
  100% {
    background-position: 500px 500px;
  }
}
@keyframes gradientAnimation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.navbar-movil__div--container {
  /*posicion y layout*/
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #FFA500;
  box-shadow: 0px 0px 13px #000000;
  /*misc*/
}
.navbar-movil__div--container .navbar-movil__div--fondo-animado {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden !important;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-image: url("../img/globales/fondo-animado.png");
  background-position: top 10% right 0%;
  /*misc*/
  background-repeat: repeat;
  background-position: left top;
  background-size: 1500px;
  transform: scale(1);
  animation: animacion-fondo-menu 100s infinite;
}
.navbar-movil__div--container .navbar-movil__button--hamburguesa {
  /*posicion y layout*/
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  /*modelo de caja*/
  border-radius: 10px;
  padding: 5px;
  /*tipografia*/
  /*visual*/
  border: none;
  background-color: #00CED1;
  /*misc*/
}
.navbar-movil__div--container .navbar-movil__button--hamburguesa .navbar-movil__img--hamburguesa {
  /*posicion y layout*/
  width: 30px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  opacity: 1;
  /*misc*/
  transition: ease-in-out 0.5s;
}
.navbar-movil__div--container .navbar-movil__button--hamburguesa .navbar-movil__img--cerrar {
  /*posicion y layout*/
  width: 30px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  opacity: 1;
  /*misc*/
  transition: ease-in-out 0.5s;
}
.navbar-movil__div--container .navbar-movil__div--container-usuario-puntos {
  /*posicion y layout*/
  width: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  z-index: 15;
  /*modelo de caja*/
  /*tipografia*/
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  /*visual*/
  border-radius: 50px;
  background-color: #00CED1;
  /*misc*/
  transition: ease-in-out 0.5s;
}
.navbar-movil__div--container .navbar-movil__div--container-usuario-puntos .navbar-movil__img--cofre {
  /*posicion y layout*/
  width: 30px;
  /*modelo de caja*/
  margin: 10px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.navbar-movil__div--container .navbar-movil__div--container-usuario-puntos .navbar-movil__div--paquete-puntos {
  /*posicion y layout*/
  position: relative;
  right: 15px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.762);
  /*modelo de caja*/
  margin: 5px;
  box-sizing: border-box;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.navbar-movil__div--container .navbar-movil__div--container-usuario-puntos .navbar-movil__div--paquete-puntos .navbar-movil__p--puntos {
  /*posicion y layout*/
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  margin: 5px;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.navbar-movil__div--container .navbar-movil__div--container-usuario-puntos .navbar-movil__p--usuario {
  /*posicion y layout*/
  /*modelo de caja*/
  margin: 10px;
  /*tipografia*/
  font-weight: bold;
  /*visual*/
  /*misc*/
}

@keyframes animacion-fondo-menu {
  0% {
    background-position: 500px 500px;
  }
  50% {
    background-position: 100px 100px;
  }
  100% {
    background-position: 500px 500px;
  }
}
.navbar-movil__div--container-opciones {
  /*posicion y layout*/
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 80px;
  width: 100%;
  height: 0vh;
  overflow: hidden;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: rgb(230, 230, 230);
  /*misc*/
  transition: ease-in-out 1s;
}
.navbar-movil__div--container-opciones .navbar-movil__div--grid {
  /*posicion y layout*/
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 300px;
  height: 480px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.navbar-movil__div--container-opciones .navbar-movil__div--grid .navbar-movil__button-opcion {
  /*posicion y layout*/
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  border-radius: 15px;
  text-decoration: none;
  /*visual*/
  border: none;
  /*misc*/
}
.navbar-movil__div--container-opciones .navbar-movil__div--grid .navbar-movil__button-opcion a {
  text-decoration: none;
}
.navbar-movil__div--container-opciones .navbar-movil__div--grid .navbar-movil__button-opcion img {
  /*posicion y layout*/
  width: 100px;
  position: relative;
  z-index: 5;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.navbar-movil__div--container-opciones .navbar-movil__div--grid .navbar-movil__button-opcion-azul {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #00CED1;
  /*misc*/
}
.navbar-movil__div--container-opciones .navbar-movil__div--grid .navbar-movil__button-opcion-naranja {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: #FFA500;
  /*misc*/
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.cabecero__header {
  /*posicion y layout*/
  position: relative;
  top: 80px;
  width: 100%;
  height: calc(100vh - 80px);
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: rgb(216, 216, 216);
  /*misc*/
}
.cabecero__header .cabecero__div--container {
  /*posicion y layout*/
  position: relative;
  width: 100%;
  height: 100%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-image: url("../img/menu/cabecero.png");
  background-size: cover;
  background-position: 40%;
  /*misc*/
}
.cabecero__header .cabecero__div--container .cabecero__div--logos {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.cabecero__header .cabecero__div--container .cabecero__div--logos .cabecero__img--logo-principal {
  /*posicion y layout*/
  width: 130px;
  position: absolute;
  top: 10px;
  right: 20px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: white;
  border-radius: 20px;
  /*misc*/
}
.cabecero__header .cabecero__div--container .cabecero__div--logos .cabecero__img--logo-aplicativo {
  /*posicion y layout*/
  width: 270px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
@media screen and (min-width: 400px) {
  .cabecero__header .cabecero__div--container .cabecero__div--logos .cabecero__img--logo-aplicativo {
    /*posicion y layout*/
    width: 350px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 500px) {
  .cabecero__header .cabecero__div--container .cabecero__div--logos .cabecero__img--logo-aplicativo {
    /*posicion y layout*/
    width: 400px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 800px) {
  .cabecero__header .cabecero__div--container .cabecero__div--logos {
    /*posicion y layout*/
    position: absolute;
    right: 210px;
    width: 40%;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .cabecero__header .cabecero__div--container .cabecero__div--logos .cabecero__img--logo-principal {
    /*posicion y layout*/
    position: relative;
    left: 0;
    top: 50px;
    width: 260px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .cabecero__header .cabecero__div--container .cabecero__div--logos .cabecero__img--logo-aplicativo {
    /*posicion y layout*/
    width: 470px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.cabecero__header .cabecero__div--container .cabecero__div--adorno-bajo {
  /*posicion y layout*/
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  height: 80px;
  /*modelo de caja*/
  overflow: hidden !important;
  /*tipografia*/
  /*visual*/
  background-image: url("../img/globales/fondo-animado.png");
  background-position: top 10% right 0%;
  /*misc*/
  background-repeat: repeat;
  background-position: left top;
  background-size: 1500px;
  transform: scale(1);
  /*misc*/
  animation: animacion-fondo-menu 100s infinite;
  background-color: #FFA500;
  box-shadow: 0px 0px 13px #000000;
}

@keyframes animacion-fondo-menu {
  0% {
    background-position: 500px 500px;
  }
  50% {
    background-position: 100px 100px;
  }
  100% {
    background-position: 500px 500px;
  }
}
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.categoria__div--container {
  /*posicion y layout*/
  width: 100%;
  position: relative;
  top: 80px;
  align-items: center;
  justify-content: center;
  overflow: auto;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.categoria__div--container .categoria__div--container-img {
  /*posicion y layout*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.categoria__div--container .categoria__div--container-img .categoria__img--letrero-categoria {
  /*posicion y layout*/
  position: relative;
  top: 80px;
  width: 200px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
@media screen and (min-width: 370px) {
  .categoria__div--container .categoria__div--container-img .categoria__img--letrero-categoria {
    /*posicion y layout*/
    width: 250px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.categoria__div--container .categoria__div--grid {
  /*posicion y layout*/
  width: fit-content;
  margin: 130px auto;
  display: grid;
  grid-template-columns: repeat(2, 130px);
  grid-auto-rows: 130px;
  grid-row-gap: 4px;
  grid-column-gap: 4px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: rgb(255, 255, 255);
  /*misc*/
}
@media screen and (min-width: 370px) {
  .categoria__div--container .categoria__div--grid {
    /*posicion y layout*/
    width: fit-content;
    margin: 130px auto;
    display: grid;
    grid-template-columns: repeat(2, 140px);
    grid-auto-rows: 140px;
    grid-row-gap: 4px;
    grid-column-gap: 4px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: rgb(255, 255, 255);
    /*misc*/
  }
}
@media screen and (min-width: 400px) {
  .categoria__div--container .categoria__div--grid {
    /*posicion y layout*/
    width: fit-content;
    margin: 130px auto;
    display: grid;
    grid-template-columns: repeat(2, 150px);
    grid-auto-rows: 150px;
    grid-row-gap: 4px;
    grid-column-gap: 4px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: rgb(255, 255, 255);
    /*misc*/
  }
}
@media screen and (min-width: 450px) {
  .categoria__div--container .categoria__div--grid {
    /*posicion y layout*/
    width: fit-content;
    margin: 130px auto;
    display: grid;
    grid-template-columns: repeat(2, 180px);
    grid-auto-rows: 180px;
    grid-row-gap: 4px;
    grid-column-gap: 4px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-color: rgb(255, 255, 255);
    /*misc*/
  }
}
@media screen and (min-width: 800px) {
  .categoria__div--container .categoria__div--grid {
    /*posicion y layout*/
    grid-template-columns: repeat(3, 180px);
    grid-auto-rows: 180px;
    grid-row-gap: 4px;
    grid-column-gap: 4px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.juegoDesbloqueado__div--container {
  /*posicion y layout*/
  position: relative;
  z-index: 10;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.juegoDesbloqueado__div--container .juegoDesbloqueado__button {
  /*posicion y layout*/
  width: auto;
  height: 100%;
  border: none;
  padding: 0;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.juegoDesbloqueado__div--container .juegoDesbloqueado__button .juegoDesbloqueado__img {
  /*posicion y layout*/
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border-radius: 0%;
  /*misc*/
  cursor: pointer;
  transition: ease-in-out 0.3s;
}
.juegoDesbloqueado__div--container .juegoDesbloqueado__button .juegoDesbloqueado__img:hover {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border-radius: 100%;
  /*misc*/
}
.juegoDesbloqueado__div--container .juegoDesbloqueado__button .juegoDesbloqueado__img--cinta {
  /*posicion y layout*/
  position: absolute;
  z-index: 20;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.juegoDesbloqueado__div--container .juegoDesbloqueado__button .juegoDesbloqueado__p--nombre {
  /*posicion y layout*/
  width: 80%;
  position: absolute;
  z-index: 21;
  bottom: 24.5px;
  left: 0;
  right: 0;
  margin: auto;
  /*modelo de caja*/
  /*tipografia*/
  font-size: 13px;
  font-family: "pirata one", sans-serif;
  font-weight: bold;
  /*visual*/
  color: rgb(34, 34, 34);
  /*misc*/
}
@media screen and (min-width: 800px) {
  .juegoDesbloqueado__div--container .juegoDesbloqueado__button .juegoDesbloqueado__img--cinta {
    /*posicion y layout*/
    position: absolute;
    z-index: 20;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    width: 170px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .juegoDesbloqueado__div--container .juegoDesbloqueado__button .juegoDesbloqueado__p--nombre {
    /*posicion y layout*/
    bottom: 42px;
    /*modelo de caja*/
    /*tipografia*/
    font-size: 20px;
    /*visual*/
    /*misc*/
  }
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.juegoBloqueado__div--container {
  /*posicion y layout*/
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100%;
  /*modelo de caja*/
  margin: 0;
  /*tipografia*/
  /*visual*/
  background-color: rgb(0, 0, 0);
  /*misc*/
}
.juegoBloqueado__div--container .juegoBloqueado__img {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.juegoBloqueado__div--container .juegoBloqueado__img-candado {
  /*posicion y layout*/
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  transition: transform ease-in-out 1s;
}

.juegoBloqueado__div--container:hover .juegoBloqueado__img-candado {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  transform: translate(-50%, -50%) rotate(360deg);
  animation: girarInfinito 1.4s linear infinite;
}

@keyframes girarInfinito {
  0% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1.2);
  }
  25% {
    transform: translate(-50%, -50%) rotate(80deg) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1.2);
  }
  75% {
    transform: translate(-50%, -50%) rotate(-80deg) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1.2);
  }
}
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.separador__div--container {
  /*posicion y layout*/
  position: relative;
  top: 80px;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  color: #ffffff;
  /*visual*/
  /*misc*/
}
.separador__div--container .separador__img--separador {
  /*posicion y layout*/
  margin: auto;
  z-index: 3;
  width: 110px;
  overflow: visible;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.77));
  /*misc*/
}
@media screen and (min-width: 500px) {
  .separador__div--container .separador__img--separador {
    /*posicion y layout*/
    position: absolute;
    width: 150px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
body {
  height: 100%;
}

.lista-tarjetas__div--container {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  position: relative;
  top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: rgb(9, 9, 9);
  /*misc*/
}
.lista-tarjetas__div--container .lista-tarjetas__div--imagen-fondo {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  opacity: 0.5;
  background-position: center;
  background-size: cover;
  background-image: url("../img/menu/cabecero.png");
  background-attachment: fixed;
  /*misc*/
}
.lista-tarjetas__div--container .lista-tarjetas__div--grid-tarjetas {
  /*posicion y layout*/
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-row-gap: 50px;
  grid-column-gap: 4px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
@media screen and (min-width: 530px) {
  .lista-tarjetas__div--container .lista-tarjetas__div--grid-tarjetas {
    /*posicion y layout*/
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-row-gap: 50px;
    grid-column-gap: 10px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 800px) {
  .lista-tarjetas__div--container .lista-tarjetas__div--grid-tarjetas {
    /*posicion y layout*/
    margin: 100px 0;
    grid-template-columns: repeat(3, auto);
    /*modelo de caja*/
    grid-row-gap: 35px;
    grid-column-gap: 15px;
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 1100px) {
  .lista-tarjetas__div--container .lista-tarjetas__div--grid-tarjetas {
    /*posicion y layout*/
    grid-template-columns: repeat(4, auto);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

@media screen and (min-width: 800px) {
  .lista-tarjetas__div--container {
    /*posicion y layout*/
    height: max-content;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.lista-tarjetas__div--container--cargador {
  /*posicion y layout*/
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: rgb(224, 224, 224);
  /*misc*/
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.tarjeta-bloqueada__div--container {
  /*posicion y layout*/
  position: relative;
  width: 250px;
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border-radius: 10px;
  background-position: top 10% right 0%;
  background-image: linear-gradient(45deg, #5435ff, #f0ff67, #43ff43, #c64cff, #ff5151, #ff52f6, #4c38ff, #43ff4c, #5435ff, #f0ff67, #ff52f6, #52daff, #5435ff, #f0ff67, #43ff43, #c64cff, #ff5151, #ff52f6, #4c38ff, #43ff4c, #5435ff, #f0ff67, #ff52f6, #52daff);
  background-size: 1400% 1400%;
  /*misc*/
  animation: moveGradient 15s ease-in-out infinite;
}
.tarjeta-bloqueada__div--container .tarjeta-bloqueada__button {
  /*posicion y layout*/
  width: 94%;
  height: 97%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  border: none;
  background-color: rgb(28, 28, 28);
  border-radius: 10px;
  /*misc*/
  cursor: pointer;
}
.tarjeta-bloqueada__div--container .tarjeta-bloqueada__button:hover {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.tarjeta-bloqueada__div--container .tarjeta-bloqueada__button:hover img {
  /*posicion y layout*/
  width: 0%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.tarjeta-bloqueada__div--container .tarjeta-bloqueada__button:hover .tarjeta-bloqueada__p--precio {
  /*posicion y layout*/
  bottom: 170px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.tarjeta-bloqueada__div--container .tarjeta-bloqueada__button:hover .tarjeta-bloqueada__p--reclamar {
  /*posicion y layout*/
  bottom: 90px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  color: rgb(228, 228, 228);
  /*misc*/
}
.tarjeta-bloqueada__div--container .tarjeta-bloqueada__button img {
  /*posicion y layout*/
  width: 60%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  transition: ease-in-out 0.3s;
  /*misc*/
}
.tarjeta-bloqueada__div--container .tarjeta-bloqueada__button .tarjeta-bloqueada__p--precio {
  /*posicion y layout*/
  width: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 30px;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  /*visual*/
  color: white;
  border-radius: 20px;
  border: solid 3px rgb(74, 74, 74);
  background-color: #2e2e2e;
  /*misc*/
  transition: ease-in-out 0.3s;
}
.tarjeta-bloqueada__div--container .tarjeta-bloqueada__button .tarjeta-bloqueada__p--reclamar {
  /*posicion y layout*/
  width: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 10px;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  /*visual*/
  color: rgba(255, 255, 255, 0);
  /*misc*/
  transition: ease-in-out 0.3s;
}
.tarjeta-bloqueada__div--container .tarjeta-bloqueada__p--monedas-insuficientes {
  /*posicion y layout*/
  width: 90%;
  height: 220px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  /*visual*/
  border: rgb(97, 97, 97) 4px solid;
  background-color: rgb(28, 28, 28);
  background-image: url("../img/tarjetas//loro.png");
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: white;
  /*misc*/
}

@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.tarjeta-desbloqueada__div--container {
  /*posicion y layout*/
  position: relative;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.tarjeta-desbloqueada__div--container .tarjeta-desbloqueada__div--container--animacion {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  pointer-events: none;
}
.tarjeta-desbloqueada__div--container .tarjeta-desbloqueada__div--container--animacion .tarjeta-desbloqueada__div--animacion-desbloqueo {
  /*posicion y layout*/
  width: 100%;
  height: 100%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
  pointer-events: none;
}
.tarjeta-desbloqueada__div--container .tarjeta-desbloqueada__img {
  /*posicion y layout*/
  position: relative;
  width: 250px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.tarjeta-desbloqueada__div--container .tarjeta-desbloqueada__img--personaje {
  /*posicion y layout*/
  position: absolute;
  top: 0;
  left: 0;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.tarjeta-desbloqueada__div--container .tarjeta-desbloqueada__div--container-nombre {
  /*posicion y layout*/
  position: absolute;
  bottom: 10px;
  width: 100%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.tarjeta-desbloqueada__div--container .tarjeta-desbloqueada__div--container-nombre img {
  /*posicion y layout*/
  position: relative;
  width: 100%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.tarjeta-desbloqueada__div--container .tarjeta-desbloqueada__div--container-nombre p {
  /*posicion y layout*/
  width: 100px;
  position: absolute;
  bottom: 75px;
  left: 0;
  right: 0;
  margin: auto;
  /*modelo de caja*/
  text-align: center;
  /*tipografia*/
  font-size: 18px;
  font-family: "pirata one", sans-serif;
  /*visual*/
  color: rgb(32, 32, 32);
  background-image: url("../img/tarjetas/cinta.png");
  border-radius: 10px;
  /*misc*/
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.transicion__div--container {
  /*posicion y layout*/
  position: relative;
  z-index: 10;
  width: 250px;
  height: 380px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: white;
  /*misc*/
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.index-movil__div--container {
  /*posicion y layout*/
  width: 100%;
  min-height: 850px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-image: url("../img/Navbar/animacion intro-09.png");
  background-position: top 10% right 0%;
  /*misc*/
  background-repeat: repeat;
  background-position: left top;
  background-size: 300%;
  transform: scale(1);
  animation: animacion-fondo-menu 120s infinite;
}

.index-movil__div--container-logo {
  /*posicion y layout*/
  width: 100%;
  position: relative;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.index-movil__div--container-logo img {
  /*posicion y layout*/
  width: 220px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-color: white;
  border-radius: 10px;
  /*misc*/
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
}

.index-movil__div--inputs-container {
  /*posicion y layout*/
  width: 90%;
  display: flex;
  position: relative;
  top: 150px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*modelo de caja*/
  padding: 0px 10px;
  margin: auto;
  /*tipografia*/
  /*visual*/
  border-radius: 10px;
  border: solid 1px #00CED1;
  backdrop-filter: blur(3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /*misc*/
}

@media screen and (min-width: 370px) {
  .index-movil__div--inputs-container {
    /*posicion y layout*/
    width: 300px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
.index-movil__field--input-usuario {
  /*posicion y layout*/
  width: 95%;
  height: 30px;
  margin-bottom: 10px;
  /*modelo de caja*/
  /*tipografia*/
  text-align: center;
  font-family: "Poppins", sans-serif;
  /*visual*/
  border-radius: 25px;
  border: solid 3px #00CED1;
  /*misc*/
}

.index-movil__field--input-contrasena {
  /*posicion y layout*/
  width: 95%;
  height: 30px;
  /*modelo de caja*/
  /*tipografia*/
  text-align: center;
  font-family: "Poppins", sans-serif;
  /*visual*/
  border-radius: 25px;
  border: solid 3px #00CED1;
  /*misc*/
}

.index-movil__img--imagen-usuario-container {
  /*posicion y layout*/
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.index-movil__img--imagen-usuario-container .index-movil__img--imagen-usuario {
  /*posicion y layout*/
  width: 30px;
  /*modelo de caja*/
  margin: auto;
  padding: 10px;
  /*tipografia*/
  /*visual*/
  border-radius: 100%;
  background-color: white;
  /*misc*/
}

.index-movil__div--container-button-submit {
  /*posicion y layout*/
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.index-movil__div--container-button-submit button {
  /*posicion y layout*/
  width: 200px;
  height: 30px;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: bold;
  /*visual*/
  border: none;
  color: white;
  border-radius: 15px;
  background-color: #00CED1;
  /*misc*/
}

.index-movil__h4--error {
  /*posicion y layout*/
  width: 80%;
  position: absolute;
  top: 560px;
  /*modelo de caja*/
  padding: 5px 10px;
  margin: auto;
  left: 0;
  right: 0;
  /*tipografia*/
  font-size: 15px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: red;
  /*visual*/
  background-color: rgb(255, 105, 105);
  border-radius: 25px;
  /*misc*/
}

.index-movil__h4--contrasena {
  /*posicion y layout*/
  top: 595px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

.index-movil__div--contenedor-loader {
  /*posicion y layout*/
  position: relative;
  top: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.animacion-login__div--container {
  /*posicion y layout*/
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  overflow: hidden !important;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-image: url("../img/Index-login/diseno-3d-1c.png");
  background-position: top 10% right 0%;
  /*misc*/
  background-repeat: repeat;
  background-position: left left;
  background-size: 600%;
  transform: scale(1);
  animation: moverImagen 100s infinite;
}
.animacion-login__div--container .animacion-login__img--logo {
  /*posicion y layout*/
  width: 220px;
  position: absolute;
  left: 0;
  right: 0;
  top: 120px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  background-color: white;
  border-radius: 10px;
  /*misc*/
}
.animacion-login__div--container .animacion-login__img--barco {
  /*posicion y layout*/
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 220px;
  width: 200px;
  /*modelo de caja*/
  margin: auto;
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.animacion-login__div--container .animacion-login__img--mar {
  /*posicion y layout*/
  position: absolute;
  width: 3000px;
  left: -1000px;
  bottom: 0;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}

@keyframes moverImagen {
  0% {
    background-position: 0% 0; /* Comienza desde la izquierda */
  }
  100% {
    background-position: 100% 0; /* Se desplaza hasta la derecha del viewport */
  }
}
/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
.suscribete__div--container {
  /*posicion y layout*/
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  background-image: url("../img/globales/suscribete.png");
  background-position: 28%;
  background-size: cover;
  /*misc*/
}
.suscribete__div--container .suscribete__div-paquete-info {
  /*posicion y layout*/
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.suscribete__div--container .suscribete__div-paquete-info .suscribete__img--logo-galileo {
  /*posicion y layout*/
  position: relative;
  top: 45px;
  width: 60%;
  background-color: white;
  border-radius: 10px;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.suscribete__div--container .suscribete__div-paquete-info .suscribete__img--logo-aplicativo {
  /*posicion y layout*/
  width: 100%;
  /*modelo de caja*/
  /*tipografia*/
  /*visual*/
  /*misc*/
}
.suscribete__div--container .suscribete__div-paquete-info .suscribete__p--vencio-suscripcion {
  /*posicion y layout*/
  /*modelo de caja*/
  /*tipografia*/
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  text-align: center;
  /*visual*/
  /*misc*/
}
.suscribete__div--container .suscribete__div-paquete-info .suscribete__button--comprar-suscripcion {
  /*posicion y layout*/
  width: 90%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*modelo de caja*/
  /*tipografia*/
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  /*visual*/
  background-color: #00CED1;
  color: white;
  border: none;
  border-radius: 20px;
  /*misc*/
  cursor: pointer;
}
@media screen and (min-width: 350px) {
  .suscribete__div--container .suscribete__div-paquete-info .suscribete__img--logo-galileo {
    /*posicion y layout*/
    width: 190px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .suscribete__div--container .suscribete__div-paquete-info .suscribete__img--logo-aplicativo {
    /*posicion y layout*/
    width: 310px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .suscribete__div--container .suscribete__div-paquete-info .suscribete__p--vencio-suscripcion {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    text-align: center;
    /*visual*/
    /*misc*/
  }
  .suscribete__div--container .suscribete__div-paquete-info .suscribete__button--comprar-suscripcion {
    /*posicion y layout*/
    width: 320px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}
@media screen and (min-width: 800px) {
  .suscribete__div--container .suscribete__div-paquete-info .suscribete__img--logo-galileo {
    /*posicion y layout*/
    width: 230px;
    top: 70px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .suscribete__div--container .suscribete__div-paquete-info .suscribete__img--logo-aplicativo {
    /*posicion y layout*/
    width: 400px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .suscribete__div--container .suscribete__div-paquete-info .suscribete__p--vencio-suscripcion {
    /*posicion y layout*/
    /*modelo de caja*/
    /*tipografia*/
    font-size: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    text-align: center;
    /*visual*/
    /*misc*/
  }
  .suscribete__div--container .suscribete__div-paquete-info .suscribete__button--comprar-suscripcion {
    /*posicion y layout*/
    position: relative;
    width: 500px;
    height: 41px;
    bottom: 20px;
    /*modelo de caja*/
    /*tipografia*/
    font-size: 30px;
    /*visual*/
    /*misc*/
  }
}
.suscribete__div--container .suscribete__button--cerrar-sesion {
  /*posicion y layout*/
  width: 200px;
  height: 30px;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
  /*modelo de caja*/
  /*tipografia*/
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  /*visual*/
  border: none;
  border-radius: 10px;
  background-color: #D60000;
  color: #E4E4E4;
  /*misc*/
  cursor: pointer;
}
@media screen and (min-width: 800px) {
  .suscribete__div--container .suscribete__div-paquete-info {
    /*posicion y layout*/
    position: absolute;
    top: 60px;
    left: 100px;
    width: 500px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
  .suscribete__div--container .suscribete__button--cerrar-sesion {
    /*posicion y layout*/
    width: 200px;
    height: 30px;
    position: absolute;
    top: 50px;
    right: 50px;
    left: auto;
    margin: 0;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}

@media screen and (min-width: 800px) {
  .suscribete__div--container {
    /*posicion y layout*/
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: auto;
    align-items: auto;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    background-image: url("../img/globales/suscribete.png");
    background-position: 28%;
    background-size: cover;
    /*misc*/
  }
}
@media screen and (max-height: 600px) {
  .suscribete__div--container {
    /*posicion y layout*/
    height: 700px;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/
  }
}


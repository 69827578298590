/*posicion y layout*/
/*modelo de caja*/
/*tipografia*/
/*visual*/
/*misc*/
@import "_variables";

.categoria__div--container {
    /*posicion y layout*/
    width: 100%;
    position: relative;
    top: 80px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    /*modelo de caja*/
    /*tipografia*/
    /*visual*/
    /*misc*/

    .categoria__div--container-img {
        /*posicion y layout*/
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        /*misc*/

        .categoria__img--letrero-categoria {
            /*posicion y layout*/
            position: relative;
            top: 80px;
            width: 200px;
            /*modelo de caja*/
            margin: auto;
            /*tipografia*/
            /*visual*/;
            /*misc*/
        }

        @media screen and (min-width: 370px){
            .categoria__img--letrero-categoria {
                /*posicion y layout*/
                width: 250px;
                /*modelo de caja*/
                /*tipografia*/
                /*visual*/
                /*misc*/
            }
        }
    }



    .categoria__div--grid {
        /*posicion y layout*/
        width: fit-content;
        margin:  130px auto;
        display: grid;
        grid-template-columns: repeat(2, 130px);
        // grid-template-rows: repeat(auto-fill, minmax(130px, 130px));
        grid-auto-rows: 130px;
        grid-row-gap: 4px;
        grid-column-gap: 4px;
        /*modelo de caja*/
        /*tipografia*/
        /*visual*/
        background-color: rgb(255, 255, 255);
        /*misc*/
    }

    @media screen and (min-width: 370px){
        .categoria__div--grid {
            /*posicion y layout*/
            width: fit-content;
            margin:  130px auto;
            display: grid;
            grid-template-columns: repeat(2, 140px);
            // grid-template-rows: repeat(auto-fill, minmax(130px, 130px));
            grid-auto-rows: 140px;
            grid-row-gap: 4px;
            grid-column-gap: 4px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            background-color: rgb(255, 255, 255);
            /*misc*/
        }
    }

    @media screen and (min-width: 400px){
        .categoria__div--grid {
            /*posicion y layout*/
            width: fit-content;
            margin:  130px auto;
            display: grid;
            grid-template-columns: repeat(2, 150px);
            // grid-template-rows: repeat(auto-fill, minmax(130px, 130px));
            grid-auto-rows: 150px;
            grid-row-gap: 4px;
            grid-column-gap: 4px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            background-color: rgb(255, 255, 255);
            /*misc*/
        }
    }


    @media screen and (min-width: 450px){
        .categoria__div--grid {
            /*posicion y layout*/
            width: fit-content;
            margin:  130px auto;
            display: grid;
            grid-template-columns: repeat(2, 180px);
            // grid-template-rows: repeat(auto-fill, minmax(130px, 130px));
            grid-auto-rows: 180px;
            grid-row-gap: 4px;
            grid-column-gap: 4px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            background-color: rgb(255, 255, 255);
            /*misc*/
        }
    }

    @media screen and (min-width: 800px){
        .categoria__div--grid {
            /*posicion y layout*/
            grid-template-columns: repeat(3, 180px);
            // grid-template-rows: repeat(auto-fill, minmax(130px, 130px));
            grid-auto-rows: 180px;
            grid-row-gap: 4px;
            grid-column-gap: 4px;
            /*modelo de caja*/
            /*tipografia*/
            /*visual*/
            /*misc*/
        }
    }
}